import React, { createContext, useContext, useState, useEffect } from 'react';
import { getSupabaseClient, BUSINESS_TYPES } from '../supabaseClient';

const BusinessContext = createContext();

export const BusinessProvider = ({ children }) => {
  const [businessType, setBusinessType] = useState(() => {
    // Initialize from localStorage
    return localStorage.getItem('businessType') || null;
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeBusinessType = async () => {
      try {
        // First check localStorage
        const storedType = localStorage.getItem('businessType');
        if (storedType) {
          setBusinessType(storedType);
          setLoading(false);
          return;
        }

        // If not in localStorage, check databases
        const cleaningClient = getSupabaseClient(BUSINESS_TYPES.CLEANING);
        const { data: { user } } = await cleaningClient.auth.getUser();

        if (user) {
          const { data: cleaningData } = await cleaningClient
            .from('accounts')
            .select('business_type')
            .eq('id', user.id)
            .single();

          if (cleaningData) {
            setBusinessType(cleaningData.business_type);
            localStorage.setItem('businessType', cleaningData.business_type);
            return;
          }

          const laundromatClient = getSupabaseClient(BUSINESS_TYPES.LAUNDROMAT);
          const { data: laundromatData } = await laundromatClient
            .from('accounts')
            .select('business_type')
            .eq('id', user.id)
            .single();

          if (laundromatData) {
            setBusinessType(laundromatData.business_type);
            localStorage.setItem('businessType', laundromatData.business_type);
          }
        }
      } catch (err) {
        console.error('Error fetching business type:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    initializeBusinessType();
  }, []);

  const value = {
    businessType,
    setBusinessType,  // Expose this to allow updates
    loading,
    error
  };

  return (
    <BusinessContext.Provider value={value}>
      {children}
    </BusinessContext.Provider>
  );
};

export const useBusinessType = () => {
  const context = useContext(BusinessContext);
  if (!context) {
    throw new Error('useBusinessType must be used within a BusinessProvider');
  }
  return context;
};