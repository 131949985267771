import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./styles/styles.css";
import { BusinessProvider } from './context/BusinessContext';
import ErrorBoundary from './components/ErrorBoundary';

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <BusinessProvider>
          <App />
        </BusinessProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);