// src/config/maps.js
export const MAPS_CONFIG = {
  apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  libraries: ['places', 'geometry']
};

export const MAP_DEFAULTS = {
  center: {
    lat: 37.7749,
    lng: -122.4194
  },
  zoom: 12
};