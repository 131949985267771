// src/components/ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useBusinessType } from '../context/BusinessContext';
import { isRouteAccessible } from '../config/routeAccess';
import { getSupabaseClient } from '../supabaseClient';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Add this
  const { businessType, loading, setBusinessType } = useBusinessType();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // Check localStorage first
        const storedType = localStorage.getItem('businessType');
        if (storedType && !businessType) {
          setBusinessType(storedType);
        }
        const supabase = getSupabaseClient(storedType || businessType);
        const { data: { session }, error } = await supabase.auth.getSession();
        if (error || !session) {
          navigate('/login');
          return;
        }
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Auth check failed:', error);
        navigate('/login');
      } finally {
        setCheckingAuth(false);
      }
    };
    checkAuth();
  }, [businessType, navigate, setBusinessType]);

  if (loading || checkingAuth) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  // Use location.pathname instead of path prop
  if (!businessType || !isRouteAccessible(businessType, location.pathname)) {
    return <Navigate to="/home" replace />;
  }

  return children;
};

export default ProtectedRoute;