import React from 'react';
import { AlertCircle, RefreshCw } from 'lucide-react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false, 
      error: null,
      errorInfo: null,
      componentStack: null
    };
  }

  static getDerivedStateFromError(error) {
    return { 
      hasError: true, 
      error 
    };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error
    console.error('Error caught by boundary:', error);
    console.error('Component stack:', errorInfo.componentStack);

    // Update state with component stack
    this.setState({
      errorInfo: errorInfo,
      componentStack: errorInfo.componentStack
    });

    // You could also send this to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      const isDevelopment = process.env.NODE_ENV === 'development';

      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full">
            <div className="flex items-center gap-3 mb-6">
              <AlertCircle className="w-8 h-8 text-red-500" />
              <h1 className="text-2xl font-bold text-gray-900">Something went wrong</h1>
            </div>

            {/* Error Message */}
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700 mb-2">Error Details</h2>
              <div className="bg-red-50 border border-red-200 rounded-lg p-4">
                <p className="text-red-700 font-mono text-sm">
                  {this.state.error?.message || 'An unexpected error occurred'}
                </p>
              </div>
            </div>

            {/* Component Stack - Only in development */}
            {isDevelopment && this.state.componentStack && (
              <div className="mb-6">
                <h2 className="text-lg font-semibold text-gray-700 mb-2">Component Stack</h2>
                <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 overflow-auto max-h-64">
                  <pre className="text-sm font-mono text-gray-600 whitespace-pre-wrap">
                    {this.state.componentStack}
                  </pre>
                </div>
              </div>
            )}

            {/* Actions */}
            <div className="flex gap-4">
              <button
                onClick={() => window.location.reload()}
                className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
              >
                <RefreshCw className="w-4 h-4" />
                Refresh Page
              </button>

              <button
                onClick={() => window.location.href = '/'}
                className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
              >
                Go to Home
              </button>
            </div>

            {/* Help text */}
            <p className="mt-6 text-sm text-gray-500">
              If this error persists, try clearing your browser cache or contacting support.
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;